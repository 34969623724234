@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
    width: 100%;
    height: 100%;
}

.image-gallery-slide img {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}



/* .fullscreen .image-gallery-slide img {
    height: 100vh;
} */


.image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 80vh;
    max-width:max-content;

  
}