* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #F5F5F5;
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height:100%;
  overflow-x: hidden;
}
